'use strict';

$(function(){

let $window = $(window);
let $bodyHtml = $('body,html');
let $html = $('html');
let $body = $('body');
let breakPoint1 = 767;

// body固定
let scrollPosi;

function bodyFix() {
    scrollPosi = $(window).scrollTop();
    $body.css({
        position: "fixed",
        width: "100%",
        "z-index": "1",
        top: -scrollPosi,
    });
}

function bodyFixReset() {
    $body.css({
        position: "relative",
        width: "auto",
        top: "0",
    });
    $bodyHtml.scrollTop(scrollPosi);
}

// 画面幅変更時のclassリセット
$(window).on("load resize", function () {
  if ($(window).outerWidth() > 768) {
    bodyFixReset();
    $('.l-header__box').removeClass('js-active');
    $('.l-header__icon--close').removeClass('js-active');
    $('.l-header__icon--open').addClass('js-active');
  }
});

//PAGE TOP
let $scroll = $window.scrollTop();
if ($scroll == 0) {
  $('.c-pagetop').addClass('hide');
} else {
  $('.c-pagetop').removeClass('hide');
}
$window.on("scroll", function () {
  $scroll = $(this).scrollTop();
  if ($scroll == 0) {
    $('.c-pagetop').addClass('hide');
  } else {
    $('.c-pagetop').removeClass('hide');
  }
})

//スムーススクロール
$('a[href^=#]').on('click', function() {
  let href= $(this).attr("href");
  let target = $(href == "#" || href == "" ? 'html' : href);
  let position = target.offset().top;
  $bodyHtml.animate({scrollTop:position}, 500, 'swing');
  return false;
})

if(window.outerWidth <= breakPoint1){
  //SP Menu
  $('.l-header__icon--open').on('click', function(){
    $(this).removeClass('js-active');
    $('.l-header__icon--close').addClass('js-active');
    $('.l-header__box').addClass('js-active');
    bodyFix();
  })

  $('.l-header__icon--close').on('click', function(){
    $(this).removeClass('js-active');
    $('.l-header__icon--open').addClass('js-active');
    $('.l-header__box').removeClass('js-active');
    bodyFixReset();
  })

  //SP Subbox
  $('.l-header__subclose').on('click', function(){
    $(this).toggleClass('sub-active');
    $(this).next('.l-header__subbox').stop().slideToggle();
  })
}

// Infinite Scroll
let infScroll = new InfiniteScroll( '.c-achivepage__content', { // 記事を挿入していく要素を指定
  append: '.c-achivepage__box',  // 各記事の要素
  path: '.next_posts_link a',    // 次のページへのリンク要素を指定
  hideNav: '.next_posts_link',   // 指定要素を非表示にする
  button: '.c-achivepage__more', // 記事を読み込むトリガーとなる要素を指定
  scrollThreshold: false,        // スクロールで読み込む：falseで機能停止
  history: 'false'               // falseで履歴に残さない
});
infScroll();
})
